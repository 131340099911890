import { GetTransactions, Reverse, GetPaymentLogs, GetProviderLogs, WithdrawToAdmin } from "../../services/transaction";

export const getTransactions = async (context, data) => {
    context.commit("updateLoader", { fetchTransactions: true });

    return new Promise((resolve, reject) => {
        GetTransactions(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("loadTransactions", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchTransactions: false });
            });
        });
};

export const reverse = async (context, data) => {
    context.commit("updateLoader", { reverse: true });
    const transactionId = data.id ? data.id : data;
    return new Promise((resolve, reject) => {
        Reverse(transactionId)
            .then(res => {
                context.commit("reverse", {transactionId, transaction: res.data.data});
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { reverse: false });
            });
        });
};

export const getPaymentLogs = async (context, data) => {
    context.commit("updateLoader", { getPaymentLogs: true });

    return new Promise((resolve, reject) => {
        GetPaymentLogs(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("getPaymentLogs", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPaymentLogs: false });
            });
        });
};

export const getProviderLogs = async (context, data) => {
    context.commit("updateLoader", { getProviderLogs: true });

    return new Promise((resolve, reject) => {
        GetProviderLogs(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("getProviderLogs", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getProviderLogs: false });
            });
        });
};

export const withdrawToAdmin = async (context, data) => {
    context.commit("updateLoader", { withdrawToAdmin: true });
    const transactionId = data.id ? data.id : data;
    return new Promise((resolve, reject) => {
        WithdrawToAdmin(transactionId)
            .then(res => {
                context.commit("withdrawToAdmin", data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { withdrawToAdmin: false });
            });
        });
};

const TransactionAction = {
    getTransactions,
    reverse,
    getPaymentLogs,
    getProviderLogs,
    withdrawToAdmin
};

export default TransactionAction;