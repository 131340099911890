export const getAllCurrencies = (
    state, currencies
) => {
    state.currencies = currencies;
}




export const getCurrency = (state, currencies) => {
    state.currencies = currencies;
};

export const addCurrency = (state, currency) => {
    const _strgy = [
        ...state.currencies,
        currency
    ];
    console.log(_strgy);
    state.currencies = _strgy;
};

export const editCurrency = (state, currency) => {
    const currIdx = state.currencies.findIndex(
        (_currency) => _currency.id === currency.id
    );
    if (currIdx < 0) {
        return;
    }
    state.currencies[currIdx] = currency;
};

// export const toggleStrategy = ( state, strategy ) => {
//   return updateStrategy(state, strategy)
// }

export const deleteCurrency = (state, currency) => {
    const currencies = state.currencies.filter(
        (_currency) => _currency.id !== currency.id
    );
    state.currencies = currencies;
};


const CurrencyMutation = {
    getAllCurrencies,
    getCurrency,
    addCurrency,
    editCurrency,
    // toggleStrategy,
    deleteCurrency
};

export default CurrencyMutation;