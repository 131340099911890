export const fetchSummary = (state, summary) => {
    state.summary = summary;
};

export const getDashboard = (state, dashboard) => {
    state.dashboard = dashboard;
};

export const getBalances = (state, balances) => {
    state.balances = balances
}


const MetricsMutation = {
    fetchSummary,
    getDashboard,
    getBalances
};

export default MetricsMutation;