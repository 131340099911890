<template>
  <div class="flex flex-wrap">
    
    <div class="w-full lg:w-8/12 px-4">
      <card-coin-preference-list />
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <card-coin-preference />
    </div>
  </div>
</template>
<script>
import CardCoinPreference from "@/components/Cards/CardCoinPreference.vue";
import CardCoinPreferenceList from "@/components/Cards/CardCoinPreferenceList.vue";
// import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CardCoinPreference,
    CardCoinPreferenceList
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
};
</script>
