<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Businesses
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <div class="flex flex-wrap">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead class="thead-light">
            <tr>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Business
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                No. of Customers
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="has('businesses.view.all')">
            <tr 
              class="cursor-pointer"
              v-for="(business, i) in businesses().data" :key="i"
            >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ business.name }} | 
                <i>{{ business.support_email || "--" }}</i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                ({{ business.status }} {{ business.code }})
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ business.customers_count }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <button 
                  class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                  @click="$emit('activeBusinessSelected', business)"
                  v-if="(has('business.update') || has('business.confirm.update')) && has('business.show')"
                >
                  <!-- detach -->
                  <i class="fas fa-pen ml-2 text-xs"></i>
                </button>
                <button 
                  class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                  @click="handleActAsBusiness(business)"
                  v-if="has('business.show') || has('business.view')"
                >
                  <!-- detach -->
                  <i class="fas fa-hand-pointer ml-2 text-xs"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              no permission to view business
            </th>
          </tr>
        </tbody>
        </table>
      </div>
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <circular-pagination :current_page="businesses().current_page" :last_page="businesses().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CircularPagination from "@/components/Paginations/CircularPagination.vue";

export default {
  props: [],
  components: {
    CircularPagination
  },
  methods: {
    ...mapActions(["getBusinesses"]),
    ...mapGetters([
      "loaders",
      "businesses",
    ]),
    ...mapMutations(["actingAsBusiness"]),
    handleActAsBusiness(business) {
      this.actingAsBusiness(business)
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewUserPage();
    },
    fetchNewUserPage() {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv
      }
      if ( !params.get_csv ) {
        // this.updateTransactionQueryParams(params)
        this.$emit('businessChange', params)
      }
      const self = this
      this.loading = true
      console.log(params)
      this.getBusinesses(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','transactions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          // toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
  },
  data() {
    return {
      business: {},
      get_csv: false,
      pagination: {},
      pageSize: 15
    }
  }
}
</script>
