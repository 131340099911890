<template>
  <div>
    <plan-stats @statChange="changeParam"></plan-stats>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
          <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="has('plan.view')">
              <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
                User Plans
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="has('open.order.multiple.view')">
              <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
                Open Orders
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="has('pending.order.multiple.view')">
              <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
                Pending Orders
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="has('plans.show')">
              <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-orange-500': openTab === 4}">
                Setup
              </a>
            </li>
          </ul>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
                <div class="w-full px-4" :class="{'lg:w-8/12': Object.values(planUserModel).length > 0}">
                  <card-user-plan-list :plans="userPlan()" :param="param" @selectedPlan="changePlanUser"></card-user-plan-list>
                </div>
                <transition name="bounce">
                  <div class="w-full lg:w-4/12 px-4" v-if="Object.values(planUserModel).length > 0">
                    <card-user-plan :selectedPlan="planUserModel" @close="handleCloseUserPlan" ></card-user-plan>
                  </div>
                </transition>
              </div>

              <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
                <div class="w-full px-4">
                  <card-open-order-list></card-open-order-list>
                </div>
              </div>

              <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
                <div class="w-full px-4">
                  <card-pending-order-list></card-pending-order-list>
                </div>
              </div>

              <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}" class="flex flex-wrap">
                <div class="w-full lg:w-8/12 px-4">
                  <CardPlans :selectedPlan="plan" />
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <card-plan-list :plans="plans()" @selectedPlan="changePlan" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardPlans from "@/components/Cards/CardPlans.vue";
import CardUserPlan from "@/components/Cards/CardUserPlan.vue";
import CardUserPlanList from "@/components/Cards/CardUserPlanList.vue";
import CardOpenOrderList from "@/components/Cards/CardOpenOrderList.vue";
import CardPendingOrderList from "@/components/Cards/CardPendingOrderList.vue";
import CardPlanList from "@/components/Cards/CardPlanList.vue";
import PlanStats from "@/components/Stats/PlanStats.vue";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  components: {
    PlanStats,
    CardPlans,
    CardPlanList,
    CardUserPlan,
    CardUserPlanList,
    CardOpenOrderList,
    CardPendingOrderList
  },
  data() {
    return {
      plan: {},
      planUserModel: {},
      openTab: 1,
      param: {}
    }
  },
  mounted() {
    this.getAllPlans();
    this.fetchPlanUser();
    this.getBusinesses({size: 100});
  },
  methods: {
    ...mapActions(["getAllPlans", "fetchPlanUser", "getBusinesses"]),
    ...mapGetters(["plans", "userPlan"]),
    changePlan(plan) {
      this.plan = plan
    },
    changePlanUser(planUser) {
      this.planUserModel = planUser
    },
    changeParam(param) {
      this.param = param
      
      this.fetchPlanUser(param).then( response => {
        if ( param.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','plans.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      })
    },
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    handleCloseUserPlan(){
      this.planUserModel = {}
    }
  }
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
