export const getBusinesses = (state, businesses) => {
    state.businesses = businesses;
};

export const getUserBusinesses = (state, businesses) => {
    state.businesses = businesses;
};

export const confirmBusiness = (state, business) => {
    state.business = business;
};

export const actingAsBusiness = (state, business) => {
    state.actingAsBusiness = business
    localStorage.setItem('business', JSON.stringify(business))
}

export const stopActingAsBusiness = (state) => {
    state.actingAsBusiness = {}
    localStorage.removeItem('business')
}

export const addBusiness = (state, business) => {
    state.businesses.data = [business, ...state.businesses.data];
};

export const updateBusiness = (state, business) => {
    const businessIndex = state.businesses.data.findIndex(
        (_business) => _business.id === business.id
    );
    if (businessIndex < 0) {
        return;
    }
    state.businesses.data[businessIndex] = business;
};

export const deleteBusiness = (state, business) => {
    const businesses = state.businesses.data.filter(
        (_business) => _business.id !== business.id
    );
    state.businesses.data = businesses;
};

export const getCommission = (state, commissions) => {
    state.commissions = commissions;
};

export const payCommission = (state, commission) => {
    const commissionIndex = state.commissions.data.findIndex(
        (_commission) => _commission.id === commission.id
    );
    if (commissionIndex < 0) {
        return;
    }
    state.commissions.data[commissionIndex] = commission;
};

    
export const payBulkCommission = (s, c) => {
    console.log(s,c)
}

export const updateCommission = (state, commission) => {
    const commissionIndex = state.commissions.data.findIndex(
        (_commission) => _commission.id === commission.id
    );
    if (commissionIndex < 0) {
        return;
    }
    state.commissions.data[commissionIndex] = commission;
};
    
export const deleteCommission = (state, commission) => {
    const commissions = state.commissions.data.filter(
        (_commission) => _commission.id !== commission.id
    );
    state.commissions.data = commissions;
};

export const leaveBusiness = (state, {code, userPlan_id}) => {
    state.userPlan.data = (state.userPlan.data || []).map( p => {
        if(p.id !== userPlan_id) {
            return p
        } else {
            p.user.clusters = (p.user.clusters || []).filter( c => c.code !== code)
            return p;
        }
    })
}


const BusinessMutation = {
    getBusinesses,
    confirmBusiness,
    getUserBusinesses,
    addBusiness,
    updateBusiness,
    deleteBusiness,
    actingAsBusiness,
    stopActingAsBusiness,
    getCommission,
    payCommission,
    payBulkCommission,
    updateCommission,
    deleteCommission,
    leaveBusiness
};

export default BusinessMutation;