import http from "./http";

export const GetAllPredictions = async() => {
    return await http.get("/predictions");
};

export const GetMarket = async() => {
    return await http.get("/market");
};


export const AddStrategy = async data => {
    return await http.post("/strategy", data);
};

export const UpdateStrategy = async(id, data) => {
    return await http.put(`/strategy/${id}`, data);
};

export const ToggleStrategy = async(id) => {
    return await http.post(`/strategy/${id}/toggle`);
};

export const GetStrategy = async() => {
    return await http.get("/strategy");
};

export const DeleteStrategy = async id => {
    return await http.get(`/strategy/${id}`);
};