<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
  >
    <div class="px-6">
      <div class="mt-3">
        <h3
          class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
        >
          Connect Credentials
        </h3>
        <div class="mb-2 text-blueGray-600 mt-10">
          <i class="fas fa-key mr-2 text-lg text-blueGray-400"></i>
          API Key - BBCkLwe89000RvBas
        </div>
        <div class="mb-2 text-blueGray-600">
          <i class="fas fa-key mr-2 text-lg text-blueGray-400"></i>
          Secret Key - KYZ12394Lkm900293
        </div>
      </div>
      <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12 px-4">
            <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
              {{ userProfile().about }}
            </p>
            <!-- <a href="javascript:void(0);" class="font-normal text-emerald-500">
              Show more
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      team2,
    };
  },
  methods: {
    ...mapGetters(["userProfile"]),
  }
};
</script>
