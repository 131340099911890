export const getPermissions = (
    state,
    permissions
) => {
    state.permissions = permissions;
};

export const updatePermission = (
    state, permission
) => {
    const permissionIdx = state.permissions.findIndex(
        (_permission) => _permission.id === permission.id
    );

    if (permissionIdx < 0) {
        return;
    }

    state.permissions[permissionIdx] = permission;
}

export const getRole = (
    state, role
) => {
    const roles = state.roles.filter(
        (_role) => _role.id === role.id
    );

    state.roles = [...roles, role];
}

export const getRoles = (
    state,
    roles
) => {
    state.roles = roles;
};

export const addRole = (state, role) => {
    state.roles = {...state.roles, role}
}

export const updateRole = (
    state, role
) => {
    const roleIdx = state.roles.findIndex(
        (_role) => _role.id === role.id
    );

    if (roleIdx < 0) {
        return;
    }

    state.roles[roleIdx] = role;
}

export const deleteRole = (
    state, roleId
) => {
    const roles = state.roles.filter(
        (_role) => _role.id === roleId
    );

    state.roles = roles;
}

export const addPermission = (
    state, permission
) => {

    const roleIdx = state.roles.findIndex(
        (_role) => _role.id === permission.roleId
    );

    if (roleIdx < 0) {
        return;
    }

    const foundRole = state.roles[roleIdx];

    foundRole.permissions = {
        ...foundRole.permissions,
        permission
    }
}

export const detachPermission = (
    state, permission
) => {

    const roleIdx = state.roles.findIndex(
        (_role) => _role.id === permission.roleId
    );

    if (roleIdx < 0) {
        return;
    }

    const foundrole = state.roles[roleIdx];

    const permissions = foundrole.permissions.filter(
        (_permission) => _permission.id !== permission.permissionId
    );

    foundrole.permissions = permissions
}

export const addUser = (
    state, user
) => {

    const roleIdx = state.roles.findIndex(
        (_role) => _role.id === user.roleId
    );

    if (roleIdx < 0) {
        return;
    }

    const foundRole = state.roles[roleIdx];

    foundRole.users = {
        ...foundRole.users,
        user
    }
}

export const detachUser = (
    state, user
) => {

    const roleIdx = state.roles.findIndex(
        (_role) => _role.id === user.roleId
    );

    if (roleIdx < 0) {
        return;
    }

    const foundrole = state.roles[roleIdx];

    const users = foundrole.users.filter(
        (u) => u.id !== user.userId
    );

    foundrole.users = users
}

const RoleMutation = {
    addRole, 
    updateRole, 
    getRoles, 
    getRole, 
    deleteRole, 
    addPermission, 
    updatePermission,
    detachPermission, 
    getPermissions,
    addUser,
    detachUser
};

export default RoleMutation;