<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Coin Whitelisted
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>


    <div class="block w-full overflow-x-auto">
      <div class="container px-2 mx-auto">
        <div class="flex flex-column w-full">
          <div class="w-1/3 px-2" v-for="(cp, i) in coinPreferences()" :key="i" >
            <span v-if="cp.action==='whitelist'" class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
              {{ cp.coin }} <i class="fa fa-times text-red-500 text-xs p-2 rounded-full bg-gray-500" @click="removeCoin(cp.coin)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
      
    <hr/>
    <br/>
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Coin blacklisted
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>


    <div class="block w-full overflow-x-auto">
      <div class="container px-2 mx-auto">
        <div class="flex flex-column w-full">
          <div class="w-1/3 px-2" v-for="(cp, i) in coinPreferences()" :key="i">
            <span v-if="cp.action==='blacklist'" class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
              {{ cp.coin }} <i class="fa fa-times text-red-500 text-xs p-2 rounded-full bg-gray-500" @click="removeCoin(cp.coin)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="has('prediction.view')">
      <hr/>
      <br/>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap">

          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700">
              First Prediction
            </h3>
          </div>
          <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
              <li v-for="(p, i) in predictions().first_match" :key="i" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(i)" v-bind:class="{'text-emerald-600 bg-white': openTab !== i, 'text-white bg-emerald-600': openTab === i}">
                  {{ p.name }}
                </a>
              </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                  <div v-for="(p, i) in predictions().first_match" :key="i" v-bind:class="{'hidden': openTab !== i, 'block': openTab === i}">

                    <img :src="p.logo"/>
                    <br/>

                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead class="thead-light">
                        <tr>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Description
                          </th>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            name
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.name }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            symbol
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.symbol }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            price
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.price }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            market cap
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.market_cap.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 1h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_1h.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 24h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_24h }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 7d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_7d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 30d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_30d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            max supply
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.max_supply }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            address
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>
      <br/>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap">

          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700">
              Second Prediction
            </h3>
          </div>
          <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
              <li v-for="(p, i) in predictions().second_match" :key="i" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(i)" v-bind:class="{'text-emerald-600 bg-white': openTab !== i, 'text-white bg-emerald-600': openTab === i}">
                  {{ p.name }}
                </a>
              </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                  <div v-for="(p, i) in predictions().second_match" :key="i" v-bind:class="{'hidden': openTab !== i, 'block': openTab === i}">

                    <img :src="p.logo"/>
                    <br/>

                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead class="thead-light">
                        <tr>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Description
                          </th>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            name
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.name }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            symbol
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.symbol }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            price
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.price }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            market cap
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.market_cap.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 1h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_1h.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 24h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_24h }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 7d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_7d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 30d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_30d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            max supply
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.max_supply }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            address
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>
      <br/>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap">

          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700">
              Third Prediction
            </h3>
          </div>
          <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
              <li v-for="(p, i) in predictions().third_match" :key="i" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(i)" v-bind:class="{'text-emerald-600 bg-white': openTab !== i, 'text-white bg-emerald-600': openTab === i}">
                  {{ p.name }}
                </a>
              </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                  <div v-for="(p, i) in predictions().third_match" :key="i" v-bind:class="{'hidden': openTab !== i, 'block': openTab === i}">

                    <img :src="p.logo"/>
                    <br/>

                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead class="thead-light">
                        <tr>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Description
                          </th>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            name
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.name }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            symbol
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.symbol }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            price
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.price }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            market cap
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.market_cap.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 1h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_1h.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 24h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_24h }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 7d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_7d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 30d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_30d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            max supply
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.max_supply }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            address
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>
      <br/>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap">

          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700">
              Forth Prediction
            </h3>
          </div>
          <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
              <li v-for="(p, i) in predictions().forth_match" :key="i" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(i)" v-bind:class="{'text-emerald-600 bg-white': openTab !== i, 'text-white bg-emerald-600': openTab === i}">
                  {{ p.name }}
                </a>
              </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                  <div v-for="(p, i) in predictions().forth_match" :key="i" v-bind:class="{'hidden': openTab !== i, 'block': openTab === i}">

                    <img :src="p.logo"/>
                    <br/>

                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead class="thead-light">
                        <tr>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Description
                          </th>
                          <th
                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            name
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.name }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            symbol
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.symbol }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            price
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.price }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            market cap
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.market_cap.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 1h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_1h.toFixed(8) }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 24h
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_24h }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 7d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_7d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            percent change 30d
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.percent_change_30d }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            max supply
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.max_supply }}
                          </td>
                        </tr>

                        <tr class="cursor-pointer">
                          <th
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                          >
                            address
                          </th>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ p.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
    this.getAllPredictions();
    this.getCoinPreference();
  },
  data() {
    return {
      openTab: 1
    }
  },
  methods: {
    ...mapActions(["getAllPredictions", "getCoinPreference", "removeCoinPreference"]),
    ...mapGetters(["predictions", "coinPreferences"]),
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    removeCoin(coin) {
      this.removeCoinPreference(coin)
    }
  }
}
</script>
