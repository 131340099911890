import { 
  AddRole, 
  UpdateRole, 
  GetRoles, 
  GetRole, 
  DeleteRole, 
  AddPermission, 
  DetachPermission, 
  GetPermissions,
  UpdatePermission,
  AddUser,
  DetachUser
} from "@/services/role";

export const getRole = (context, id) => {
  context.commit("updateLoader", { getRole: true });
  return new Promise((resolve, reject) => {
    GetRole(id)
      .then(res => {
        context.commit("getRole", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { getRole: false });
      });
    });
};

export const getRoles = (context) => {
  context.commit("updateLoader", { getRoles: true });
  return new Promise((resolve, reject) => {
    GetRoles()
      .then(res => {
        context.commit("getRoles", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { getRoles: false });
      });
    });
};

export const addRole = (context, data) => {
  context.commit("updateLoader", { addRole: true });
  return new Promise((resolve, reject) => {
    AddRole(data)
      .then(res => {
        context.commit("addRole", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { addRole: false });
      });
    });
};

export const updateRole = (context, data) => {
  context.commit("updateLoader", { updateRole: true });
  return new Promise((resolve, reject) => {
    UpdateRole(data)
      .then(res => {
        context.commit("updateRole", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { updateRole: false });
      });
    });
};

export const deleteRole = (context, id) => {
  context.commit("updateLoader", { deleteRole: true });
  return new Promise((resolve, reject) => {
    DeleteRole(id)
      .then(res => {
        context.commit("deleteRole", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { deleteRole: false });
      });
    });
};

export const getPermissions = (context) => {
  context.commit("updateLoader", { getPermissions: true });
  return new Promise((resolve, reject) => {
    GetPermissions()
      .then(res => {
        context.commit("getPermissions", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { getPermissions: false });
      });
    });
};

export const updatePermission = (context, data) => {
  context.commit("updateLoader", { updatePermission: true });
  return new Promise((resolve, reject) => {
    UpdatePermission(data)
      .then(res => {
        context.commit("updatePermission", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { updatePermission: false });
      });
    });
};

export const addPermission = (context, data) => {
  context.commit("updateLoader", { addPermission: true });
  const {role, permission} = data;
  return new Promise((resolve, reject) => {
    AddPermission(role, permission)
      .then(res => {
        context.commit("addPermission", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { addPermission: false });
      });
    });
};

export const detachPermission = (context, data) => {
  context.commit("updateLoader", { detachPermission: true });
  const {role, permission} = data;
  return new Promise((resolve, reject) => {
    DetachPermission(role, permission)
      .then(res => {
        context.commit("detachPermission", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { detachPermission: false });
      });
    });
};

export const addUser = (context, data) => {
  context.commit("updateLoader", { addUser: true });
  const {role, user} = data;
  return new Promise((resolve, reject) => {
    AddUser(role, user)
      .then(res => {
        context.commit("addUser", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { addUser: false });
      });
    });
};

export const detachUser = (context, data) => {
  context.commit("updateLoader", { detachUser: true });
  const {role, user} = data;
  return new Promise((resolve, reject) => {
    DetachUser(role, user)
      .then(res => {
        context.commit("detachUser", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { detachUser: false });
      });
    });
};

const RoleAction = {
  addRole, 
  updateRole, 
  getRoles, 
  getRole, 
  deleteRole, 
  addPermission, 
  detachPermission, 
  getPermissions,
  updatePermission,
  addUser,
  detachUser
};

export default RoleAction;
