import http from "./http";

export const GetCampaigns = async params => {
    return await http.get("/campaigns", {params});
}

export const AddCampaign = async data => {
    return await http.post("/campaigns", data);
}

export const EditCampaign = async(id, data) => {
    return await http.put(`/campaigns/${id}`, data);
}

export const DeleteCampaign = async id => {
    return await http.delete(`/campaigns/${id}`);
}

export const SendCampaign = async id => {
    return await http.get(`/campaigns/send/${id}`);
}

export const ReadCampaignMessage = async id => {
    return await http.get(`/campaigns/read/${id}`);
}

export const CountRecepients = async id => {
    return await http.get(`/campaigns/count/${id}`);
}