<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-4 pb-4 pt-2">
    <div class="px-4 md:px-10 mx-auto w-full" v-if="has('plan.summary.view')">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div v-for="(stat, i) in Object.values(planSummary())" :key="i" class="w-full lg:w-6/12 xl:w-3/12 px-4 mt-1">
            <card-stats
              :statSubtitle="`${(stat?.status?.toLowerCase()==='retry'? 'DUE': stat.status)} ${params?.type?.toUpperCase() || 'plan'}`"
              :statTitle="formatter().format(stat.amount || 0)"
              :statArrow="`${'up'}`"
              :statCount="`${stat.total}`"
              :statPercentColor="`text-orange-500`"
              :statDescripiron="`${stat?.status?.toLowerCase()==='retry'? 'due': stat?.status?.toLowerCase()} ${params.type || 'plan'} count`"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>

          <div v-for="(stat, i) in userPlanDefaultStatSummary()" :key="i" class="w-full lg:w-6/12 xl:w-3/12 px-4 mt-1">
            <card-stats
              :statSubtitle="`${stat.status} ${params?.type?.toUpperCase() || 'plan'}`"
              :statTitle="formatter().format(stat.amount || 0)"
              :statArrow="`${'up'}`"
              :statCount="`${stat.total}`"
              :statPercentColor="`text-orange-500`"
              :statDescripiron="`${stat?.status?.toLowerCase()} ${params.type || 'plan'} count`"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap my-4 text-white">
        <h4>Filters</h4>
      </div>
      <div class="flex flex-wrap mt-4">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select 
              @change="fetchFilteredSummary" 
              v-model="params.business_id" 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              placeholder="Business"
              >
              <option value="">--select--</option>
              <option v-for='(business, i) in businesses().data' :key="i" :value="business.id">
                {{ business.name }} | {{ business.code }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select 
              @change="fetchFilteredSummary" 
              v-model="params.type" 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              placeholder="Type"  
            >
              <option value="">--select--</option>
              <option v-for='(t, i) in ["loan", "crypto", "savings", "investment", "estate"]' :key="i" :value="t">
              {{ t }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select 
            @change="fetchFilteredSummary" 
            v-model="params.category" 
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
            placeholder="Plan"
            >
              <option value="">--select--</option>
              <template v-for='(plan, i) in plans()' >
                <option :value="plan.name" v-if="params.type===plan.type" :key="i">
                {{ plan.name }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select 
                @change="durationFilterChange" 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Period"
              >
              <option value="">--select--</option>
              <option v-for='(t, i) in ["24hrs", "1week", "30days", "3months", "6months", "1year"]' :key="i" :value="t">
              {{ t }} Ago
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select
              @change="fetchFilteredSummary" 
              v-model="params.status" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              placeholder="Status"
              >
              <option value="">--select--</option>
              <option v-for="(t, i) in ['INITIATED', 'PENDING', 'FAILED', 'ACTIVE', 'DECLINED', 'RETRY', 'COMPLETED', 'WITHDRAWN', 'APPROVED', 'DUE', 'DEFAULT', 'BAD']" :key="i" :value="t">
              {{ t }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <input @keyup="asyncFetchFilteredSearch" v-model="params.search" placeholder="reference/name/email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <input 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              id="start-date-range-plan-stats"
              type="text"
              placeholder="Start Date"
            />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <input 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" 
              id="end-date-range-plan-stats"
              type="text"
              placeholder="End Date"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap mt-4">
        <div class="w-full lg:w-3/12 px-4 justify-right">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="downloadSummary"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'

import datepicker from 'js-datepicker'

export default {
  components: {
    CardStats
  },
  created() {
  },
  mounted() {
    this.getPlanSummary({})
    const self = this
    setTimeout( function() {
      self.start_date = datepicker("#start-date-range-plan-stats", {
        id: "range",
        onSelect: (_, date) => {
          self.from = date;
          self.loadCustomDateSummary()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      });

      self.end_date = datepicker("#end-date-range-plan-stats", {
        id: "range",
        onSelect: (_, date) => {
          self.to = date;
          self.loadCustomDateSummary()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      })
    }, 1000)
  },
  data() {
    return {
      params: {},
      timer: null,
      start_date: null,
      end_date: null,
      from: null,
      to: null
    }
  },
  methods: {
    ...mapActions(["getPlanSummary"]),
    ...mapGetters(["planSummary", "plans", "businesses", "userPlanDefaultStatSummary"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    },
    paramsToQuery(param = null) {
      return '?' + Object.entries(param || this.params).filter(([,v]) => v && v !== "").map(([k, v]) => `${k}=${v}`).join("&")
    },
    fetchFilteredSummary() {
      this.params = {
        ...this.params
      }

      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)});
    },
    durationFilterChange(e) {
      let to = null
      let from = null
      const value = e.target.value;
      switch (value) {
        case "24hrs":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
          break;
        case "1week":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
          break;
        case "30days":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'month').format("YYYY-MM-DD")
          break;
        case "3months":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(3, 'month').format("YYYY-MM-DD")
          break;
        case "6months":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(6, 'month').format("YYYY-MM-DD")
          break;
        case "1year":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'year').format("YYYY-MM-DD")
          break;  
        default:
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(5, 'year').format("YYYY-MM-DD")
          break;
      }
      this.params = {...this.params, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    loadCustomDateSummary() {
      const to = moment(this.to || new Date).format("YYYY-MM-DD")
      const from = moment(this.from || new Date).format("YYYY-MM-DD")
      this.params = {...this.params, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    downloadSummary() {
      this.$emit('statChange', {...this.params, get_csv: true})
    },
    asyncFetchFilteredSearch() {
      if ( this.timer ) {
        clearTimeout(this.timer);
        this.timer = null
      }
      const self = this
      this.timer = setTimeout(function () {
        self.fetchFilteredSummary()
      }, 1000)
    }
  }
};
</script>
