import http from "./http";

export const AddRole = async (role) => {
  return await http.post("/roles", role);
};

export const UpdateRole = async role => {
  const {id, ...rest} = role;
  return await http.put(`/roles/${id}`, rest);
};

export const GetRoles = async () => {
  return await http.get("/roles");
};

export const GetRole = async id => {
  return await http.get(`/roles/${id}`);
};

export const DeleteRole = async id => {
  return await http.delete(`/roles/${id}`);
};

// Permission
export const AddPermission = async (role, permission) => {
  const {id: roleId} = role;
  const {id: permissionId} = permission;
  return await http.put(`/role/${roleId}/permission/${permissionId}`);
};

export const DetachPermission = async (role, permission) => {
  const {id: roleId} = role;
  const {id: permissionId} = permission;
  return await http.delete(`/role/${roleId}/permission/${permissionId}`);
};

export const GetPermissions = async () => {
  return await http.get("/permission");
};

export const UpdatePermission = async permission => {
  const {id, ...rest} = permission;
  return await http.patch(`/permission/${id}`, rest);
};

// User
export const AddUser = async (role, user) => {
  const {id: roleId} = role;
  const {id: userId} = user;
  return await http.put(`/role/${roleId}/user/${userId}`);
};

export const DetachUser = async (role, user) => {
  const {id: roleId} = role;
  const {id: userId} = user;
  return await http.delete(`/role/${roleId}/user/${userId}`);
};