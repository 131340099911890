import http from "./http";

export const GetEmails = async (_q) =>
  await http.get("/notification/email" + _q);

export const GetSmS = async (_q) =>
  await http.get("/notification/sms" + _q);

export const SendEmail = async (notification) =>
  await http.post("/notification/email", notification);

export const SendSmS = async (notification) =>
  await http.post("/notification/sms", notification);

export const GetAds = async (_q) => await http.get("/ads" + _q);

export const GetAd = async (id) => await http.get("/ads/" + id);

export const SaveAds = async (ads) => {
  http.defaults.headers = {
    "Content-Type": "multipart/form-data",
    "x-auth-id": "assets",
    Accept: "*/*"
  };
  const res = await http.post("/ads", ads);
  http.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return res;
};

export const UpdateAds = async (id, ads) =>
  await http.put("/ads/" + id, ads);

export const DeleteAds = async (id) => await http.delete("/ads/" + id);

export const ToggleAds = async (id) =>
  await http.get("/ads/toggle/" + id);

export const LikeAds = async (id) => await http.get("/ads/like/" + id);
