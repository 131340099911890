import { GetCandleStick, GetPrices } from "@/services/coins";
import { AddCoinPreference, GetCoinPreference, GetCoinType, GetOpenOrders, GetPendingOrders, OrdersSell, PurchasePendingOrder, RemoveCoinPreference, TerminateTrade } from "../../services/coins";

export const getCandleStick = (context, data) => {
    context.commit("updateLoader", { getCandleStick: true });
    context.commit("updateToast", false);
    return new Promise((resolve, reject) => {
        GetCandleStick(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("getCandleStick", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCandleStick: false });
                context.commit("updateToast", true);
            });
    });
};

export const getPrices = (context, data) => {
    context.commit("updateLoader", { getPrices: true });
    context.commit("updateToast", false);
    return new Promise((resolve, reject) => {
        GetPrices(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("getPrices", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPrices: false });
                context.commit("updateLoader", true);
            });
    });
};

export const getCoinType = (context) => {
    context.commit("updateLoader", { getCoinType: true });
    return new Promise((resolve, reject) => {
        GetCoinType()
            .then(res => {
                const result = res.data.data;
                context.commit("getCoinType", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCoinType: false });
            });
    });
};

export const getCoinPreference = (context) => {
    context.commit("updateLoader", { getCoinPreference: true });
    return new Promise((resolve, reject) => {
        GetCoinPreference()
            .then(res => {
                const result = res.data.data;
                context.commit("getCoinPreference", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCoinPreference: false });
            });
    });
};

export const addCoinPreference = (context, data) => {
    context.commit("updateLoader", { addCoinPreference: true });
    return new Promise((resolve, reject) => {
        AddCoinPreference(data.coin, { action: data.action })
            .then(res => {
                const result = res.data.data;
                context.commit("addCoinPreference", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addCoinPreference: false });
            });
    });
};

export const removeCoinPreference = (context, coin) => {
    context.commit("updateLoader", { removeCoinPreference: true });
    return new Promise((resolve, reject) => {
        RemoveCoinPreference(coin)
            .then(res => {
                const result = res.data.data;
                context.commit("removeCoinPreference", coin);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { removeCoinPreference: false });
            });
    });
};

export const terminateTrade = (context, id) => {
    context.commit("updateLoader", { terminateTrade: true });
    return new Promise((resolve, reject) => {
        TerminateTrade(id)
            .then(res => {
                const result = res.data.data;
                // context.commit("terminateTrade", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { terminateTrade: false });
            });
    });
};

export const getOpenOrders = (context) => {
    context.commit("updateLoader", { getOpenOrders: true });
    return new Promise((resolve, reject) => {
        GetOpenOrders()
            .then(res => {
                const result = res.data.data;
                context.commit("getOpenOrders", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getOpenOrders: false });
            });
    });
};

export const getPendingOrders = (context) => {
    context.commit("updateLoader", { getPendingOrders: true });
    return new Promise((resolve, reject) => {
        GetPendingOrders()
            .then(res => {
                const result = res.data.data;
                context.commit("getPendingOrders", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPendingOrders: false });
            });
    });
};

export const purchasePendingOrder = (context, data) => {
    context.commit("updateLoader", { purchasePendingOrder: true });
    return new Promise((resolve, reject) => {
        PurchasePendingOrder(data)
            .then(res => {
                const result = res.data.data;
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { purchasePendingOrder: false });
            });
    });
};

export const ordersSell = (context, data) => {
    context.commit("updateLoader", { ordersSell: true });
    return new Promise((resolve, reject) => {
        OrdersSell(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("ordersSell", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { ordersSell: false });
            });
    });
};

const CoinsAction = {
    getCandleStick,
    getPrices,
    getCoinType,
    getCoinPreference,
    addCoinPreference,
    removeCoinPreference,
    terminateTrade,
    getOpenOrders,
    getPendingOrders,
    purchasePendingOrder,
    ordersSell
};

export default CoinsAction;