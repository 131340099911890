<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-commission />
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-commission color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardCommission from "@/components/Cards/CardCommission.vue";

export default {
  components: {
    CardCommission,
  },
};
</script>
