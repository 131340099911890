import { GetAllPlans, Subscribe } from "@/services/plan";
import { 
    AddPlan, 
    GetPlanSummary, 
    AttachCoinType, 
    DeletePlan, 
    DetachCoinType, 
    FetchPlanUser, 
    UpdatePlan, 
    ConfirmLoan, 
    TerminatePlan, 
    UpdatePlanUser, 
    RepayLoan,
    UpdateSavings,
    PlanConfigBulk
} from "../../services/plan";

export const getAllPlans = (context, data = {}) => {
    context.commit("updateLoader", { getPlan: true });
    return new Promise((resolve, reject) => {
        let queryString = ""
        if (data && data.q) {
            queryString = data.q;
            delete data.q;
        }
        GetAllPlans(queryString)
            .then(res => {
                const result = res.data.data;
                context.commit("getPlans", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPlan: false });
            });
    });
};

export const getPlanSummary = (context, data = {}) => {
    context.commit("updateLoader", { getPlanSummary: true });
    return new Promise((resolve, reject) => {
        let queryString = ""
        if (data && data.q) {
            queryString = data.q;
            delete data.q;
        }
        GetPlanSummary(queryString)
            .then(res => {
                const result = res.data.data;
                context.commit("getPlanSummary", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getPlanSummary: false });
            });
    });
};

export const addPlan = (context, data) => {
    context.commit("updateLoader", { addPlan: true });
    return new Promise((resolve, reject) => {
        AddPlan(data)
            .then(res => {
                const result = res.data.data;
                context.commit("addPlan", data);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addPlan: false });
            });
    });
};

export const updatePlan = (context, data) => {
    context.commit("updateLoader", { updatePlan: true });
    return new Promise((resolve, reject) => {
        const planId = data.id;
        delete data.id;
        UpdatePlan(planId, data)
            .then(res => {
                const result = res.data.data;
                data.id = planId
                context.commit("updatePlan", data);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updatePlan: false });
            });
    });
};

export const deletePlan = (context, data) => {
    context.commit("updateLoader", { deletePlan: true });
    return new Promise((resolve, reject) => {
        const planId = data.id;
        DeletePlan(planId)
            .then(res => {
                const result = res.data.data;
                context.commit("deletePlan", planId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deletePlan: false });
            });
    });
};

export const subscribePlan = (context, data) => {
    context.commit("updateLoader", { subscribe: true });
    return new Promise((resolve, reject) => {
        Subscribe(data)
            .then(res => {
                const result = res.data.data;
                context.commit("subscribe", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { subscribe: false });
            });
    });
};

export const attachCoinType = (context, data) => {
    context.commit("updateLoader", { attachCoinType: true });
    return new Promise((resolve, reject) => {
        AttachCoinType(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("attachCoinType", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { attachCoinType: false });
            });
    });
};

export const detachCoinType = (context, data) => {
    context.commit("updateLoader", { detachCoinType: true });
    return new Promise((resolve, reject) => {
        DetachCoinType(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("detachCoinType", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { detachCoinType: false });
            });
    });
};

export const fetchPlanUser = (context, data) => {
    context.commit("updateLoader", { fetchPlanUser: true });
    return new Promise((resolve, reject) => {
        FetchPlanUser(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("fetchPlanUser", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchPlanUser: false });
            });
    });
};

export const updatePlanUser = (context, data) => {
    context.commit("updateLoader", { updatePlanUser: true });
    return new Promise((resolve, reject) => {
        const planUserId = data.id;
        delete data.id;
        UpdatePlanUser(planUserId, data)
            .then(res => {
                const result = res.data.data;
                data.id = planUserId
                // context.commit("updatePlanUser", data);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updatePlanUser: false });
            });
    });
};

export const confirmLoan = (context, data) => {
    context.commit("updateLoader", { confirmLoan: true });
    return new Promise((resolve, reject) => {
        const userPlanId = data.id;
        ConfirmLoan({}, userPlanId)
            .then(res => {
                const result = res.data.data;
                context.commit("confirmLoan", userPlanId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmLoan: false });
            });
    });
}

export const terminatePlan = (context, data) => {
    context.commit("updateLoader", { terminatePlan: true });
    return new Promise((resolve, reject) => {
        const userPlanId = data.id;
        TerminatePlan(userPlanId)
            .then(res => {
                const result = res.data.data;
                context.commit("terminatePlan", userPlanId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { terminatePlan: false });
            });
    });
}

export const repayLoan = (context, data) => {
    context.commit("updateLoader", { repayLoan: true });
    return new Promise((resolve, reject) => {
        const loadId = data.id;
        delete data.id;
        RepayLoan(data, loadId)
            .then(res => {
                const result = res.data.data;
                // context.commit("repayLoan", loadId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { repayLoan: false });
            });
    });
}

export const updateSavings = (context, data) => {
    context.commit("updateLoader", { updateSavings: true });
    return new Promise((resolve, reject) => {
        const savingsId = data.id;
        delete data.id;
        UpdateSavings(data, savingsId)
            .then(res => {
                const result = res.data.data;
                // context.commit("updateSavings", savingsId);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateSavings: false });
            });
    });
}

export const planConfigBulk = (context, data) => {
    context.commit("updateLoader", { planConfigBulk: true });
    return new Promise((resolve, reject) => {
        PlanConfigBulk(data)
            .then(res => {
                const result = res.data.data;
                context.commit("planConfigBulk", data);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { planConfigBulk: false });
            });
    });
}


const PlanAction = {
    getAllPlans,
    addPlan,
    deletePlan,
    updatePlan,
    subscribePlan,
    attachCoinType,
    detachCoinType,
    fetchPlanUser,
    updatePlanUser,
    getPlanSummary,
    confirmLoan,
    terminatePlan,
    repayLoan,
    updateSavings,
    planConfigBulk
};

export default PlanAction;