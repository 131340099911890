export const fetchQuestions = (state, questions) => {
    state.questions = questions;
}

export const addQuestion = (state, question) => {
    const _questions = [
        ...state.questions,
        question
    ];
    state.questions = _questions;
}

export const updateQuestion = (state, question) => {
    const idx = state.questions.findIndex(
        (_question) => _question.id === question.id
    );
    if (idx < 0) {
        return;
    }
    state.questions[idx] = question;
}

export const removeQuestion = (state, question) => {
    const questions = state.questions.filter(
        (_questions) => _questions.id !== question.id
    );
    state.questions = questions;
}

export const addAnswer = (state, answer) => {

    const idx = state.questions.findIndex(
        (_question) => _question.id === answer.question_id
    );
    if (idx < 0) {
        return;
    }

    const question = state.questions[idx];

    const _answers = [
        ...question.answers,
        answer
    ];
    state.questions[idx].answers = _answers;
}

export const editAnswer = (state, answer) => {
    const idx = state.questions.findIndex(
        (_question) => _question.id === answer.question_id
    );
    if (idx < 0) {
        return;
    }

    const question = state.questions[idx];

    const idxA = question.answers.findIndex(
        (_answer) => _answer.id === answer.id
    );

    if (idxA < 0) {
        return;
    }

    question.answers[idxA] = answer;

    state.questions[idx] = question;

}

export const removeAnswer = (state, answer) => {
    const idx = state.questions.findIndex(
        (_question) => _question.id === answer.question_id
    );
    if (idx < 0) {
        return;
    }

    const question = state.questions[idx];

    const answers = question.answers.filter(
        (_answer) => _answer.id === answer.id
    );

    question.answers = answers;

    state.questions[idx] = question;
}

const QuestionMutation = {
    fetchQuestions,
    addQuestion,
    updateQuestion,
    removeQuestion,
    addAnswer,
    editAnswer,
    removeAnswer
};

export default QuestionMutation;