import http from "./http";

export const GetAllPlans = async(q = "") => {
    return await http.get("/plans" + q);
};

export const GetPlanSummary = async(q = "") => {
    return await http.get("/summary/plan" + q);
};

export const AddPlan = async(plan) => {
    return await http.post("/plans", plan);
};

export const UpdatePlan = async(id, plan) => {
    return await http.put("/plans/" + id, plan);
};

export const DeletePlan = async(id) => {
    return await http.delete("/plans/" + id);
};

export const Subscribe = async(data) => {
    return await http.post("/plans/subscribe", data);
};

export const AttachCoinType = async(data) => {
    return await http.post("/coin/coin-type/attach", data);
};

export const DetachCoinType = async(data) => {
    return await http.post("/coin/coin-type/detach", data);
};

export const FetchPlanUser = async (params) => {
    let data = {}
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.post("/plans/user", params, data);
}

export const UpdatePlanUser = async(planUserId, planUser) => {
    return await http.patch("/plan/user/" + planUserId, planUser);
};

export const ConfirmLoan = async (data, userPlanId) => {
    return await http.post(`/loan/confirm/${userPlanId}`);
}

export const TerminatePlan = async (userPlanId) => {
    return await http.delete(`/plan/terminate/${userPlanId}`);
}

export const RepayLoan = async (data, loanId) => {
    return await http.put(`/plan/repay/${loanId}`, data);
}

export const UpdateSavings = async (data, savingId) => {
    return await http.put(`/savings/${savingId}`, data);
}

export const PlanConfigBulk = async data => {
    return await http.post(`plan-config/bulk`, data)
}