import {
    GetCampaigns,
    AddCampaign,
    EditCampaign,
    DeleteCampaign,
    SendCampaign,
    ReadCampaignMessage,
    CountRecepients
} from "@/services/campaign";

export const getCampaigns = (context, data = {}) => {
    context.commit("updateLoader", { fetchCampaigns: true });
    return new Promise((resolve, reject) => {
        GetCampaigns(data)
            .then(res => {
                const result = res.data.data
                context.commit("fetchCampaigns", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchCampaigns: false });
            });
    });
}

export const addCampaign = (context, data) => {
    context.commit("updateLoader", { addCampaign: true });
    return new Promise((resolve, reject) => {
        AddCampaign(data)
            .then(res => {
                const result = res.data.data
                context.commit("addCampaign", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addCampaign: false });
            });
    });
}

export const editCampaign = (context, data) => {
    context.commit("updateLoader", { editCampaign: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        EditCampaign(id, data)
            .then(res => {
                const result = res.data.data
                context.commit("editCampaign", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { editCampaign: false });
            });
    });
}

export const deleteCampaign = (context, data) => {
    context.commit("updateLoader", { deleteCampaign: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        DeleteCampaign(id)
            .then(res => {
                const result = res.data.data
                context.commit("deleteCampaign", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteCampaign: false });
            });
    });
}

export const sendCampaign = (context, data) => {
    context.commit("updateLoader", { sendCampaign: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        SendCampaign(id)
            .then(res => {
                const result = res.data.data
                context.commit("sendCampaign", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { sendCampaign: false });
            });
    });
}

export const readCampaignMessage = (context, data) => {
    context.commit("updateLoader", { readCampaignMessage: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        ReadCampaignMessage(id)
            .then(res => {
                const result = res.data.data
                context.commit("readCampaignMessage", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { readCampaignMessage: false });
            });
    });
}

export const countRecepients = (context, data) => {
    context.commit("updateLoader", { countRecepients: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        CountRecepients(id)
            .then(res => {
                const result = res.data.data
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { countRecepients: false });
            });
    });
}

const CampaignAction = {
    getCampaigns,
    addCampaign,
    editCampaign,
    deleteCampaign,
    sendCampaign,
    readCampaignMessage,
    countRecepients,
};

export default CampaignAction;