import http from "./http";

export const AddCurrency = async(data) => {
    return await http.post("/rates", data);
};

export const GetAllCurrencies = async() => {
    return await http.get("/rates");
};

export const GetCurrency = async(id) => {
    return await http.get("/rates/" + id);
};

export const EditCurrency = async(id, data) => {
    return await http.put("/rates/" + id, data);
};

export const DeleteCurrency = async(id) => {
    return await http.delete("/rates/" + id);
};