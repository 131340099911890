export const updateLoader = (state, status) => {
  const previous = state.loaders;
  state.loaders = {
    ...previous,
    ...status
  };
};

const LoaderMutation = {
  updateLoader
};

export default LoaderMutation;
