const BusinessGetter = {
    businesses: state => state.businesses,
    business: state => state.business,
    actingAsBusiness: state => {
        if (Object.values(state.actingAsBusiness).length > 0)
            return state.actingAsBusiness
        const business = localStorage.getItem("business");
        if (business && Object.values(JSON.parse(business)).length > 0 ) {
            return JSON.parse(business)
        }
        return null
    },
    commissions: s => s.commissions,
    commission: s => s.commission
};

export default BusinessGetter;