import {
  CreateAccount,
  CreateSetting,
  CreateSettingPin,
  DeleteSetting,
  FundAccount,
  GetMyAccount,
  UpdateAccount,
  VerifySettingPin,
  VerifyViaBVN
} from "@/services/account";
import { getCamelCaseInput, getSnakeCaseInput } from "@/utils";

export const myAccount = (context) => {
  context.commit("updateLoader", { fetchAccount: true });
  return new Promise((resolve, reject) => {
    GetMyAccount()
      .then(res => {
        const data = res.data.data;
        context.commit("fetchAccount", data);
        resolve( data );
      })
      .catch(err => {
        console.log(err);
        reject( err );
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fetchAccount: false });
      });
  });
};


export const fundAccount = (context, data) => {
  context.commit("updateLoader", { fundAccount: true });
  return new Promise((resolve, reject) => {
    FundAccount(data)
      .then(res => {
        const data = res.data.data;
        context.commit("fundAccount", data);
        resolve( data );
      })
      .catch(err => {
        console.log(err);
        reject( err );
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fundAccount: false });
      });
  });
};



export const createAccount = (context, account) => {
  context.commit("updateLoader", { createAccount: true });
  CreateAccount(account)
    .then(res => {
      context.commit("createAccount", res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { createAccount: false });
    });
};

export const updateAccount = (context, account) => {
  context.commit("updateLoader", { updateAccount: true });
  UpdateAccount(account.id, account)
    .then(res => {
      context.commit("updateAccount", res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { updateAccount: false });
    });
};

export const createSetting = (context, setting) => {
  const req = getSnakeCaseInput(setting);
  context.commit("updateLoader", { createSetting: true });
  CreateSetting(req)
    .then(res => {
      const data = getCamelCaseInput(res.data.data);
      context.commit("createSetting", data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { createSetting: false });
    });
};

export const createSettingPin = (context, setting) => {
  context.commit("updateLoader", { createSettingPin: true });
  CreateSettingPin(setting)
    .then(res => {
      context.commit("createSettingPin", res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { createSettingPin: false });
    });
};

export const verifySettingPin = (context, setting) => {
  context.commit("updateLoader", { verifySettingPin: true });
  VerifySettingPin(setting)
    .then(res => {
      context.commit("verifySettingPin", res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { verifySettingPin: false });
    });
};

export const deleteSetting = (context, setting) => {
  context.commit("updateLoader", { deleteSetting: true });
  DeleteSetting(setting.id)
    .then(res => {
      context.commit("deleteSetting", res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { deleteSetting: false });
    });
};

export const verifyViaBVN = (context, data) => {
  context.commit("updateLoader", { verifyViaBVN: true });
  return new Promise((resolve, reject) => {
    VerifyViaBVN(data)
    .then(res => {
        const data = res.data.data;
        context.commit("verifyAccount", data);
        resolve(data);
    })
    .catch(err => {
        console.log(err);
        reject(err);
    })
    .finally(() => {
        // stop loaders
        context.commit("updateLoader", { verifyViaBVN: false });
    });
});
};

const AccountAction = {
  deleteSetting,
  verifySettingPin,
  createSettingPin,
  createSetting,
  updateAccount,
  createAccount,
  myAccount,
  fundAccount,
  verifyViaBVN
};

export default AccountAction;
