import http from "./http";

export const GetBusinesses = async (params = {}) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.get("/business/fetch", data);
};

export const GetUserBusinesses = async() => {
    return await http.get("/business");
};

export const ConfirmBusiness = async id => {
    return await http.patch(`/business/confirm/${id}`);
};

export const AddBusiness = async data => {
    return await http.post("/business", data);
};

export const UpdateBusiness = async (id, data) => {
    return await http.put(`/business/${id}`, data);
};

export const DeleteBusiness = async id => {
    return await http.delete("/business/" + id);
};

export const GetCommission = async params => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.get("/commission", data);
};

export const PayCommission = async commission => {
    return await http.put(`/commission/pay/${commission}`);
};

export const PayBulkCommission = async data => {
    return await http.post(`/commission/pay-bulk`, data);
};

export const UpdateCommission = async (id, commission) => {
    return await http.patch(`/commission/${id}`, commission);
};

export const DeleteCommission = async commission => {
    return await http.delete(`/commission/${commission}`);
};

export const AddBusinessManager = async (business, data) => {
    return await http.post(`/manager/${business}/assign`, data);
};

export const LeaveBusiness = async params => {
    return await http.delete(`/business/leave`, {params});
};
