export const getPredictions = (
    state, predictions
) => {
    state.predictions = predictions;
}

export const getMarket = (
    state, market
) => {
    state.market = market;
}

export const getStrategy = (state, strategies) => {
    state.strategies = strategies;
};

export const addStrategy = (state, strategy) => {
    const _strgy = [
        ...state.strategies.data,
        strategy
    ];
    console.log(_strgy);
    state.strategies.data = _strgy;
};

export const updateStrategy = (state, strategy) => {
    const strategyIndex = state.strategies.data.findIndex(
        (_strategy) => _strategy.id === strategy.id
    );
    if (strategyIndex < 0) {
        return;
    }
    state.strategies.data[strategyIndex] = strategy;
};

export const toggleStrategy = (state, strategy) => {
    return updateStrategy(state, strategy)
}

export const deleteStrategy = (state, strategy) => {
    const strategies = state.strategies.data.filter(
        (_strategy) => _strategy.id !== strategy.id
    );
    state.strategies.data = strategies;
};


const PredictionMutation = {
    getPredictions,
    getMarket,
    getStrategy,
    addStrategy,
    updateStrategy,
    toggleStrategy,
    deleteStrategy
};

export default PredictionMutation;