<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- {{ summary() }} -->
        <!-- Card stats -->
        <div class="flex flex-wrap" v-if="has('dashboard.summary.view')">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="TRANSACTION VOLUME"
              :statTitle="summary().transaction ? (summary().transaction.query[0].volume || 0) + '/' + (summary().transaction.total[0].volume || 0) : 0"
              :statArrow="`${ summary().transaction ? ((summary().transaction.query[0].volume || 0) > (summary().transaction.compareQuery[0].volume || 0) ? 'up' : 'down') : 'up'}`"
              :statPercent="summary().transaction ? (Math.round((Math.abs((summary().transaction.compareQuery[0].volume || 1) - (summary().transaction.query[0].volume || 1)) / (summary().transaction.compareQuery[0].volume || 1)) * 10000) / 100) : 0"
              :statPercentColor="`${ summary().transaction ? ((summary().transaction.query[0].volume || 0) > (summary().transaction.compareQuery[0].volume || 0) ? 'text-emerald-500' : 'text-red-500') : 'text-orange-500'}`"
              statDescripiron="Today/Total"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="TRANSACTION VALUE"
              :statTitle="summary().transaction ? 
              formatAbbrCurrency({
                amount:summary().transaction.query[0].total_amount || 0, 
                currency: 'NGN'
              }) + '/' + formatAbbrCurrency({
                currency: 'NGN', 
                amount: summary().transaction.total[0].total_amount || 0
              }) : 0"
              :statArrow="`${ summary().transaction ? ((summary().transaction.query[0].total_amount || 0) > (summary().transaction.compareQuery[0].total_amount || 0) ? 'up' : 'down') : 'up'}`"
              :statPercent="summary().transaction ? (Math.round((Math.abs((summary().transaction.compareQuery[0].total_amount || 1) - (summary().transaction.query[0].total_amount || 1)) / (summary().transaction.compareQuery[0].total_amount || 1)) * 10000) / 100) : 0"
              :statPercentColor="`${ summary().transaction ? ((summary().transaction.query[0].total_amount || 0) > (summary().transaction.compareQuery[0].total_amount || 0) ? 'text-emerald-500' : 'text-red-500') : 'text-orange-500'}`"
              statDescripiron="Today/Total"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="USERS"
              :statTitle="summary().user ? summary().user.query + '/' + summary().user.total : 0"
              :statArrow="`${ summary().user ? (summary().user.query > summary().user.compareQuery ? 'up' : 'down') : 'up'}`"
              :statPercent="summary().user ? (Math.round((Math.abs((summary().user.compareQuery || 1) - (summary().user.query || 1)) / (summary().user.compareQuery || 1)) * 10000) / 100) : 0"
              :statPercentColor="`${ summary().user ? (summary().user.query > summary().user.compareQuery ? 'text-emerald-500' : 'text-red-500') : 'text-orange-500'}`"
              statDescripiron="Today/Total"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="SUBSCRIPTION"
              :statTitle="summary().plan ? summary().plan.query + '/' + summary().plan.total : 0"
              :statArrow="`${ summary().plan ? (summary().plan.query > summary().plan.compareQuery ? 'up' : 'down') : 'up'}`"
              :statPercent="summary().plan ? (Math.round((Math.abs((summary().plan.compareQuery || 1) - (summary().plan.query || 1)) / (summary().plan.compareQuery || 1)) * 10000) / 100) : 0"
              :statPercentColor="`${ summary().plan ? (summary().plan.query > summary().plan.compareQuery ? 'text-emerald-500' : 'text-red-500') : 'text-orange-500'}`"
              statDescripiron="Today/Total"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div>
        </div>
        <div class="flex flex-wrap" v-else>
          Welcome
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { mapActions, mapGetters } from 'vuex';
import { formatAbbrCurrency } from "../../utils/formatter.util"

export default {
  components: {
    CardStats,
  },
  watch: {
    '$store.state.transactionQueryParams': function(params) {
      this.getSummary(params)
    }
  },
  mounted() {
    this.getSummary({})
  },
  created() {
    this.formatAbbrCurrency = formatAbbrCurrency
  },
  methods: {
    ...mapActions(["getSummary"]),
    ...mapGetters(["summary"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    }
  }
};
</script>
