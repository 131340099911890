import http from "./http";

export const AddSettings = async data => {
  return await http.post("/settings", data);
};

export const UpdateSettings = async data => {
  return await http.put("/settings", data);
};

export const GetSettings = async () => {
  return await http.get("/settings");
};

export const DeleteSettings = async id => {
  return await http.get("/settings/" + id);
};



