<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Roles
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <ul>
              <li v-for="(role, i) in roles" :key="i" @click="selectRole(role)" class="w-full flex justify-between cursor-pointer">
                <span>{{ role.name }}</span>
              </li>
            </ul>
          </div>
        </div>
    </div>

    <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [ "roles" ],
  data() {
    return {
      role: {},
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
    ]),
    selectRole(role) {
      this.role = role;
      this.$emit('selectedRole', role)
    }
  }
}
</script>