import { GetDashboard, GetSummary, GetBalances } from "../../services/metrics";

export const getSummary = (context, data) => {
    context.commit("updateLoader", { fetchSummary: true });
    GetSummary(data)
        .then(res => {
            context.commit("fetchSummary", res.data.data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { fetchSummary: false });
        });
};

export const getDashboard = (context, data = {}) => {
    context.commit("updateLoader", { getDashboard: true });
    return new Promise((resolve, reject) => {
        GetDashboard(data)
            .then(res => {
                const result = res.data.data
                context.commit("getDashboard", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getDashboard: false });
            });
    });
};

export const getBalances = (context) => {
    context.commit("updateLoader", { getBalances: true });
    return new Promise((resolve, reject) => {
        GetBalances()
            .then(res => {
                const result = res.data.data
                context.commit("getBalances", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getBalances: false });
            });
    });
};


const MetricsAction = {
    getSummary,
    getDashboard,
    getBalances
};

export default MetricsAction;