<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Roles
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <ul>
              <li v-for="(role, i) in roles" :key="i" @click="selectRole(role)" class="w-full flex justify-between cursor-pointer">
                <span>{{ role.name }} </span>
                <small 
                  @click="removeRole(role)" 
                  class="cursor-pointer hover:text-red-900"
                  v-if="has('roles.destroy')"
                >
                  x
                  delete
                </small>
              </li>
            </ul>
          </div>
        </div>
    </div>

    <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

    <div class="rounded-t bg-white mb-0 px-6 py-6" v-if="has('roles.update') || has('roles.store')">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Role</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" 
          @click="role = {}"
          v-if="has('roles.store')"
        >
          new role
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" v-if="has('roles.update') || has('roles.store')">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Role Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.role.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.role.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative w-full mb-3 px-4 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="persistRole" 
            :disabled="loaders().updateRole || loaders().addRole"
            v-if="has('roles.update') || has('roles.store')"
          >
            Save
          </button>
        </div>
        
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [ "roles" ],
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      role: {},
      permission: {}
    }
  },
  validations() {
    return {
      role: {
        name: { required }
      },
      permission: {
        name: {}
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
      "deleteRole", 
      "updateRole", 
      "addRole",
    ]),
    selectRole(role) {
      if (!this.has('role.permission.view')) {
        toaster.warning("You don't have the permission to view this role")
        return
      }
      this.role = role;
      this.$emit('selectedRole', role)
    },
    persistRole() {
      const data = this.role;
      data.id ? this.updateRole(data) : this.addRole(data).then( () => this.role = {})
    },
    removeRole(role) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      this.deleteRole(role.id);
    }
  }
}
</script>