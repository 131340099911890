export const ACTION_TYPE = {
    Add: "Add",
    Edit: "Edit"
}

export const ENVIRONMENT = process.env.NODE_ENV
export const BASE_URL = ENVIRONMENT === "production" ? "https://api.jawo.io/" : "http://localhost:8000/";
export const ASTRO_PAY_APP_ID = "PbXuYJFEKLNMleTXz1jRkADgYFisgCMnr7k8LSsJVA7Am9giVkixBNHxoo16azxA"
export const PUSHER_APP_KEY = "90861b8f263129ad9082"
export const PUSHER_APP_CLUSTER = 'eu'
export const WS_HOST = ENVIRONMENT === "production" ? 'socket.jawo.io' : 'localhost';
export const WS_PORT = ENVIRONMENT === "production" ? "" : 6001
    // export const WS_HOST = 'api.jawo.io';
    // export const WS_PORT = 6001

export const transactionList = [
    "",
    "plan-subscription", 
    "alert-subscription", 
    "loan-subscription",
    "loan-request-fee",
    "loans-payment",
    "savings-payment",
    "airtime-topup",
    "data-topup",
    "cable-tv",
    "power",
    "refund", 
    "funding", 
    "buy-coin", 
    "coin-bought", 
    "start-funding", 
    "sell-coin", 
    "send-coin",
    "withdraw",
    "commission",
    "address-verification",
    "stamp-duty",
]

export const commissionList = [
    "",
    "savings",
    "loan"
]

export const campaignList = [
    "push",
    "email",
    "sms"
]

export const recepients = [
    "all",
    "investors",
    "savers",
    "borrowers",
    "debtors"
]

export const providerList = [
    "",
    "vpay",
    "monify",
    "flutterwave",
    "korapay",
    "providus",
    "paycenter",
    "kuda",
    "paystack",
    "verifyme",
    "prembly"
]

export const logList = [
    "",
    "liveliness_verification",
    "bvn_verification",
    "nin_verification",
    "address_verification",
    "transfer_recipient",
    "direct_debit"
]