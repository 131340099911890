const BusinessState = {
    businesses: {
        data: []
    },
    business: {},
    actingAsBusiness: {},
    commissions: {
        data: []
    },
    commission: {}
};

export default BusinessState;