<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Reset Password
              </h6>
            </div>
            <!-- <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="github" />
                Github
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="google" />
                Google
              </button>
            </div> -->
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
            </div> -->
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  OTP Code
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  placeholder="OTP Code"
                  v-model="v$.auth.code.$model"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.auth.code.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  v-model="v$.auth.password.$model" 
                  :type="passwordType"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.auth.password.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Confirm Password
                </label>
                <input
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  v-model="v$.auth.confirmPassword.$model" 
                  :type="passwordType"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.auth.confirmPassword.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

             
              <div class="text-center mt-6">
                <app-button 
                  :classes="'bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'" 
                  @click="authenticate" 
                  :title="'Sign In'" 
                  :loading="loaders().resetPassword" 
                  :disabled="loaders().resetPassword || v$.auth.$invalid" 
                ></app-button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/login" class="text-blueGray-200">
              <small>Remember password?</small>
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import github from "@/assets/img/github.svg";
import google from "@/assets/img/google.svg";
import useVuelidate from '@vuelidate/core';
import AppButton from '@/components/Form/AppButton.vue';
import { required, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppButton
  },
  data() {
    return {
      github,
      google,
      auth: {},
      errors: {},
      passwordType: "password"
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      auth: {
        code: { required },
        password: { required, min: minLength(8) },
        confirmPassword: { required, min: minLength(8) },
      },
    }
  },
  methods: {
    ...mapActions([
      "resetPassword"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    authenticate() {
      //
      if ( this.auth.password !== this.auth.confirmPassword ) {
        this.errors = {
          password: "Password and confirm password are not the same."
        }
        return;
      }
      const data = {
        email: this.$route.query.email,
        code: this.auth.code,
        password: this.auth.password
      };
      // console.log(this.resetPassword(data))
      const self = this;
      this.resetPassword(data).then( function (res) {
        console.log(res);
        self.$router.push('/auth/login');
      }).catch( function (err) {
        console.log(err.data)
        self.errors = err.data ? err.data : {}
        // this.error = err;
      }).finally((e) => {
        console.log(e)
      })
    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    }
  }
};
</script>
