export const fetchCampaigns = (state, {result, data}) => {
    const { category = 'push' } = data;
    state.campaigns[category] = result;
}

export const addCampaign = (state, {result, data}) => {
    const { category = 'push' } = data;
    const _campaign = [
        ...state.campaigns[category].data,
        result
    ];
    state.campaigns[category].data = _campaign;
}

export const editCampaign = (state, {result, data}) => {
    const { category = 'push' } = data;
    const idx = state.campaigns[category].data.findIndex(
        (_c) => _c.id === result.id
    );
    if (idx < 0) {
        return;
    }
    state.campaigns[category].data[idx] = result;
}

export const sendCampaign = (state, {result, data}) => {
    const { category = 'push' } = data;
    const idx = state.campaigns[category].data.findIndex(
        (_c) => _c.id === result.id
    );
    if (idx < 0) {
        return;
    }
    const campaign = state.campaigns[category].data[idx];
    campaign.batches = [
        ...campaign.batches,
        result
    ]
    state.campaigns[category].data[idx] = campaign;
}

export const deleteCampaign = (state, {campaign, data}) => {
    const { category = 'push' } = data;
    const campaigns = state.campaigns[category].data.filter(
        (_campaigns) => _campaigns.id !== campaign.id
    );
    state.campaigns[category].data = campaigns;
}

const CampaignMutation = {
    fetchCampaigns,
    addCampaign,
    editCampaign,
    sendCampaign,
    deleteCampaign
};

export default CampaignMutation;