export const getCoinType = (state, coinType) => {
    state.coinType = coinType;
};

export const getOpenOrders = (state, openOrders) => {
    state.openOrders = openOrders
}

export const getPendingOrders = (state, pendingOrders) => {
    state.pendingOrders = pendingOrders
}

export const getCoinPreference = (state, coinPreferences) => {
    state.coinPreferences = coinPreferences;
};


export const addCoinPreference = (state, coinPreference) => {
    const coin = state.coinPreferences.find(cp => cp.coin !== coinPreference.coin)
    if (!coin) {
        state.coinPreferences.push(coinPreference);
    }

};


export const removeCoinPreference = (state, coin) => {
    const newCoinPreference = state.coinPreferences.filter(cp => cp.coin !== coin);
    state.coinPreferences = newCoinPreference;
};


const CoinMutation = {
    getCoinType,
    getCoinPreference,
    addCoinPreference,
    removeCoinPreference,
    getOpenOrders,
    getPendingOrders
};

export default CoinMutation;