import http from "./http";

export const GetMyTransactions = async(_query) =>
    await http("/transaction/me" + _query);
    
export const GetTransactions = async (params) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }
    
    return await http.post("/transaction/fetch", params, data);
}

export const Reverse = async transactionId => {
    return await http.patch(`/wallet/reverse/${transactionId}`, {});
};

export const GetPaymentLogs = async (params) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }
    
    return await http.get("/transaction/provider-logs", data);
}

export const GetProviderLogs = async (params) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }
    
    return await http.get("/activities/provider-logs", data);
}

export const WithdrawToAdmin = async data => {
    return await http.patch("/wallet/debit/to-admin", data)
}