import http from "./http";

export const GetCandleStick = async(data) => {
    return await http.post("/candle-stick", data);
};

export const GetCurrentPrice = async(data) => {
    return await http.post("/candle-stick", data);
};

export const GetPrices = async(data) => {
    return await http.post("/prices", data);
};

export const GetCoinType = async() => {
    return await http.get("/coin-type");
};

export const GetCoinPreference = async() => {
    return await http.get("/preference/coin");
};

export const AddCoinPreference = async(coin, data) => {
    return await http.post("/preference/" + coin, data);
};

export const RemoveCoinPreference = async coin => {
    return await http.delete("/preference/" + coin);
};

export const TerminateTrade = async id => {
    return await http.delete("/trade/terminate/" + id);
};

export const GetOpenOrders = async() => {
    return await http.get("/order/performance");
};

export const GetPendingOrders = async() => {
    return await http.get("/order/pending");
};

export const PurchasePendingOrder = async data => {
    return await http.post("/coin/purchase/pending", data);
};

export const OrdersSell = async data => {
    return await http.post("/order-sell", data);
};