<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Transaction
          </h3>
        </div>
        <div
          class="relative w-1/2 px-4 max-w-1/2 flex-grow flex-1 text-right"
        >
          <select @change="fetchNewUserPage('')" v-model="category" :disabled="loaders().fetchTransactions" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
            <option v-for='(cur, i) in transactionList' :key="i" >{{ cur }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Customer
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Type
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Amount/<small>Fee</small>
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Balance
              <small>Before / After</small>
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Reference
            </th>
            
          </tr>
        </thead>
        <tbody v-if="has('transaction.view')">
          <template v-if="!loaders().fetchTransactions">
            <tr v-for="(trnx, i) in transactions().data" :key="i">
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ trnx.user.name }}<br/>
                <small>({{ trnx.user.email }})</small>
              </td>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left cursor-pointer"
                :class="{'text-emerald-500': trnx.status === 'SUCCESSFUL', 'text-red-500': trnx.status === 'FAILED'}"
                :title="trnx.status"
                @click="transaction = {
                  customer_name: trnx.user.name,
                  customer_email: trnx.user.email,
                  reference: trnx.reference,
                  desc: trnx.description, 
                  user_created_at: trnx.user?.created_at,
                  ...(JSON.parse(trnx.meta) || {})
                }; toggleModal('transaction', true)"
              >
                {{ trnx.category }}
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i :class="`fas mr-1 ${trnx.type === 'CREDIT' ? 'fa-arrow-up text-emerald-500' : 'fa-arrow-down text-red-500'}`"></i>
                {{ formatAbbrCurrency({amount: trnx.gross_amount, currency: trnx.currency}) }}/<small>{{ formatAbbrCurrency({amount: trnx.charge, currency: trnx.currency}) }}</small>
              </td>   
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter(trnx.currency).format(JSON.parse(trnx.meta || "{}").balance_before || 0) }} / 
                {{ formatter(trnx.currency).format(JSON.parse(trnx.meta || "{}").balance || 0) }}
              </td>     
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 flex"
                :title="trnx.reference"
              >
                <span class="truncate flex w-1/2" style="width: 60px;">{{ trnx.reference }}</span>...
              </td>
              
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="4" rowspan="4">
                <div class="flex items-center justify-center w-full my-6">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>

        <tbody v-else>
          <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              no permission to view transaction
            </th>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="modals.transaction" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Transaction
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('transaction', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <recursive-table :data="transaction" :exclude="['id']" />
          <!-- <div class="relative p-6 flex-auto">
            <table>
              <tbody>
                <tr
                v-for="([k, v], i) in Object.entries(transaction)" :key="i"
                class="border-b-1"
                >
                  <template v-if="true">
                    <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left"
                    >{{ k.split("_").join(" ") }}</td> 
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-left flex items-center">
                      <template v-if="(typeof v === 'object') && v !== null">
                        <table>
                          <tbody>
                            <tr
                            v-for="([a, b], j) in Object.entries(v)" :key="j"
                            >
                              <td>{{ a.split("_").join(" ") }}</td>
                              <td>{{ b }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                      <template v-else>
                        {{ v }}
                      </template>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div> -->
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <router-link 
              class="text-emerald-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              :to="`/admin/transactions?search=${transaction.customer_email}`"
            >
              View more {{ transaction.customer_name }} transactions
            </router-link>

            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('transaction', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.transaction" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { transactionList, formatter } from '../../utils'
import { formatAbbrCurrency } from '../../utils/formatter.util'
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';

export default {
  components: {
    RecursiveTable
  },
  // props: ["data"],
  data() {
    return {
      category: "",
      transactionList,
      modals: {},
      transaction: {}
    }
  },
  methods: {
    ...mapActions(["getTransactions", "reverse"]),
    ...mapGetters(["transactions", "loaders"]),
    ...mapMutations(["updateTransactionQueryParams"]),
    fetchNewUserPage(q) {
      this.updateTransactionQueryParams({category: this.category, q})
      this.$emit('transactionChange', {category: this.category, q})
      this.getTransactions({category: this.category, q})
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    }
  },
  mounted() {
    this.getTransactions()
  },
  created() {
    this.formatAbbrCurrency = formatAbbrCurrency
    this.formatter = formatter
  }
}
</script>
