<template>
  <div class="py-2">
    <nav class="block">
      <ul class="flex pl-0 rounded list-none flex-wrap">
        <li v-for="(page, i) in pages" :key="i" @click="selectPage(page)" class="ml-1">
          <a href="#" :class="`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 ${activePage === page ? 'text-white bg-emerald-500' : 'bg-white text-emerald-500'} `">
            {{ page }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>

export default {
  props: ["current_page", "last_page", "size"],
  data() {
    return {
      pages: [1],
      activePage: 1
    };
  },
  mounted() {
    this.buildPages()
  },
  methods: {
    buildPages() {
      const pages = [1];
      this.activePage = this.current_page;

      if ( this.last_page === 1 ) {
        pages.push(2);
        this.pages = pages;
        return;
      }

      // prv pages in hundreds
      const hdPv = this.current_page - (this.current_page%1000)
      const hunderdsPrv = hdPv < 100 ? 100 : hdPv;
      const starthunderdsPrv = this.current_page - (this.current_page%100)
      for ( let i = hunderdsPrv; i < starthunderdsPrv; i = i + 300 ) {
        pages.push(i)
      }

      // prv pages in tenths
      const ttPv = this.current_page - (this.current_page%100)
      const tenthsPrv = ttPv < 10 ? 10 : ttPv;
      const startTenthsPrv = this.current_page - (this.current_page%10)
      for ( let i = tenthsPrv; i < startTenthsPrv; i = i + 30 ) {
        pages.push(i)
      }

      if ( this.current_page > 3 ) {
        const prev = this.current_page - 2;
        pages.push(prev);
      }

      if ( this.current_page > 2 ) {
        const prev = this.current_page - 1;
        pages.push(prev);
      }

      if ( this.current_page !== 1 ) {
        pages.push(this.current_page)
      }

      if ( this.current_page + 1 < this.last_page ) {
        const next = this.current_page + 1;
        pages.push(next);
      }

      if ( this.current_page + 2 < this.last_page ) {
        const next = this.current_page + 2;
        pages.push(next);
      }

      // pages in tenths
      const tenths = this.last_page < 100 ? this.last_page : 100;
      const startTenths = this.current_page + (10 - this.current_page%10)
      for ( let i = startTenths; i < tenths; i = i + 30 ) {
        pages.push(i)
      }

      // pages in hunderds
      const hunderds = this.last_page < 1000 ? this.last_page : 1000;
      const starthunderds = this.current_page + (100 - this.current_page%100)
      for ( let i = starthunderds; i < hunderds; i = i + 300 ) {
        pages.push(i)
      }

      // pages in thousands
      const thousands = this.last_page < 10000 ? this.last_page : 10000;
      const startthousands = this.current_page + (1000 - this.current_page%1000)
      for ( let i = startthousands; i < thousands; i = i + 3000 ) {
        pages.push(i)
      }

      pages.push(this.last_page)

      // pages.sort(function(a, b){return a - b});

      this.pages = pages

    },

    selectPage(page) {
      this.activePage = page;
      const pagination = { page, size: this.size }
      this.$emit('paginate', pagination)
    }
  },
  watch: {
    // whenever question changes, this function will run
    current_page(last_page, old_last_page) {
      if ( old_last_page != last_page) {
        this.buildPages()
      }
    }
  },
};
</script>
