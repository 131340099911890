<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Rates</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="clearForm"
          v-if="has('rates.store')"
        >
          Create New
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Rate Information
        </h6>
        <div class="flex flex-wrap">
          <!-- <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Currency
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.rate.currency.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.rate.currency.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div> -->

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Currency
              </label>
              <select v-model="v$.rate.currency.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in ["NGN", "USDC", "USDT", "USD"]' :key="i" :selected="cur === rate.currency">{{ cur }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.rate.currency.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div> 

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Rate
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.rate.rate.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.rate.rate.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Cost
        </h6>
        <div class="flex flex-wrap">
          <!-- -->
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Sell Rate
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.rate.sell_rate.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.rate.sell_rate.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Buy Rate
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.rate.buy_rate.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.rate.buy_rate.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-8" v-if="has('rates.store') || has('rates.update')" >
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="saveRate" 
            :disabled="loaders().addCurrency || loaders().editCurrency"
          >
            Save
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
// import { createToaster } from "@meforma/vue-toaster";
// const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedRate" ],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    // whenever question changes, this function will run
    selectedRate(oldRate) {
      // console.log(oldRate, newPlan)
      this.rate = oldRate;
    }
  },
  data() {
    return {
      rate: this.selectedRate,
    }
  },
  mounted() {
  },
  validations() {
    return {
      rate: {
        currency: { required },
        rate: { required },
        buy_rate: { required },
        sell_rate: { required, numeric },
        is_active: { required, numeric },
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions(["editCurrency", "addCurrency"]),
    // ...mapMutations([]),
    saveRate() {
      const data = this.rate;
      data.is_active = true;
      console.log(this.rate)
      const q = data.id ? this.editCurrency(data) : this.addCurrency(data);
      q.then( res => {
        console.log(res)
      })
    },
    clearForm() {
      this.rate = {}
    }
  }
}
</script>