<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">API</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          Documentation
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Webhook
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Event Webhook
              </label>
              <input
                type="text"
                :disabled="businesses().data && businesses().data.length === 0"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.webhook_url.$model"  
              />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-3">
          <button
            class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            :disabled="loaders().saveProfile || (businesses().data && businesses().data.length === 0) || v$.business.$invalid"
            @click="saveBusiness"
          >
            <span v-if="loaders().saveProfile" class="flex loader loader-sm"></span><span v-else>Save</span>
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});

export default {
  props: ["profile"],
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      business: {
        webhook_url  : {required},
      }
    }
  },
  data() {
    return {
      business: {},
    }
  },
  watch: {
    '$store.state.businesses': function(n) {
      this.business = n.data[0] || {}
    }
  },
  methods: {
    ...mapActions(["updateBusiness"]),
    ...mapGetters(["loaders", "businesses"]),
    saveBusiness() {
      const businesses = this.businesses().data;
      if ( businesses.length === 0 ) {
        toaster.info("Please fill the compliance details first")
        return;
      }
      const data = {
        ...this.business,
        ...businesses[0]
      }
      this.updateBusiness(data).then(() => {})
    },
  }
};
</script>