import { AddCurrency, DeleteCurrency, EditCurrency, GetAllCurrencies, GetCurrency } from "../../services/currency";

export const getAllCurrencies = (context) => {
    context.commit("updateLoader", { getAllCurrencies: true });
    return new Promise((resolve, reject) => {
        GetAllCurrencies()
            .then(res => {
                const result = res.data.data;
                context.commit("getAllCurrencies", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getAllCurrencies: false });
            });
    });
};



export const getCurrency = (context) => {
    context.commit("updateLoader", { getCurrency: true });
    return new Promise((resolve, reject) => {
        GetCurrency()
            .then(res => {
                context.commit("getCurrency", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCurrency: false });
            });
    });
};

export const addCurrency = (context, data) => {
    context.commit("updateLoader", { addCurrency: true });
    return new Promise((resolve, reject) => {
        AddCurrency(data)
            .then(res => {
                context.commit("addCurrency", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addCurrency: false });
            });
    });
};

export const editCurrency = (context, data) => {
    context.commit("updateLoader", { editCurrency: true });
    const id = data.id;
    return new Promise((resolve, reject) => {
        EditCurrency(id, data)
            .then(res => {
                context.commit("editCurrency", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { editCurrency: false });
            });
    });
};

// export const toggleStrategy = (context, data) => {
//     context.commit("updateLoader", { toggleStrategy: true });
//     return new Promise((resolve, reject) => {
//         ToggleStrategy(data.id)
//             .then(res => {
//                 context.commit("toggleStrategy", res.data.data);
//                 resolve(res.data.data);
//             })
//             .catch(err => {
//                 reject(err);
//             })
//             .finally(() => {
//                 // stop loaders
//                 context.commit("updateLoader", { toggleStrategy: false });
//             });
//     });
// };

export const deleteCurrency = (context, data) => {
    context.commit("updateLoader", { deleteCurrency: true });
    return new Promise((resolve, reject) => {
        DeleteCurrency(data.id)
            .then(res => {
                context.commit("deleteCurrency", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteCurrency: false });
            });
    });
};

const CurrencyAction = {
    getAllCurrencies,
    getCurrency,
    addCurrency,
    editCurrency,
    // toggleStrategy,
    deleteCurrency
};

export default CurrencyAction;