export const fetchAccount = (state, account) => {
    state.account = account;
};

export const fundAccount = (state, accountDetails) => {
    state.accountDetails = accountDetails;
};

export const updateProfit = (state, profit) => {
    state.profit = profit;
};




export const fetchAccountStaff = (state, staffs) => {
    state.staffs = staffs;
};


export const fetchAccountSetting = (state, setting) => {
    state.setting = setting;
};

export const createAccountBank = (state, bank) => {
    // content
    console.log(state, bank)
};


export const fetchAnAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const createAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const updateAccount = (state, account) => {
    // content
    console.log(state, account)
};
export const fetchAllStaff = (state, staffs) => {
    state.staffs = staffs;
};
export const fetchStaff = (state, staff) => {
    // state.staffs = staff
    console.log(state, staff)
};
export const createStaff = (state, staff) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === staff.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].staffs.push(staff);
};
export const updateStaff = (state, staff) => {
    // content
    console.log(state, staff)
};
export const deleteStaff = (state, staff) => {
    // content
    console.log(state, staff)
};



export const createSubAccount = (state, subAccount) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === subAccount.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].sub_accounts.push(subAccount);
};

export const fetchAllSetting = (state, settings) => {
    state.settings = settings;
};
export const fetchSetting = (state, settings) => {
    // content
    console.log(state, settings)
};
export const createSetting = (state, settings) => {
    // content
    const idx = state.accounts.data.findIndex(
        (account) => account.id === settings.accountId
    );
    if (idx < 0) {
        return;
    }
    state.accounts.data[idx].setting = settings;
};
export const createSettingPin = (state, settings) => {
    // content
    console.log(state, settings)
};
export const verifySettingPin = (state, settings) => {
    // content
    console.log(state, settings)
};
export const updateSetting = (state, settings) => {
    // content
    console.log(state, settings)
};
export const deleteSetting = (state, settings) => {
    // content
    console.log(state, settings)
};

export const verifyAccount = (state, confirm) => {

    if (confirm) {
        state.auth.meta.is_verified = true
    }
}

const AccountMutation = {
    fetchAccount,
    fundAccount,

    fetchAccountStaff,
    fetchAccountSetting,
    createAccountBank,
    fetchAnAccount,
    createAccount,
    updateAccount,
    fetchAllStaff,
    fetchStaff,
    createStaff,
    updateStaff,
    deleteStaff,
    updateProfit,
    createSubAccount,
    fetchAllSetting,
    fetchSetting,
    createSetting,
    createSettingPin,
    verifySettingPin,
    updateSetting,
    deleteSetting,
    verifyAccount
};

export default AccountMutation;