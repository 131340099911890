export const fetchWithdraw = (state, withdraws) => {
    state.withdraws = withdraws;
};

export const withdraw = (state, withdraw) => {
    console.log(state, withdraw)
        // state.withdraws.data = [...state.withdraws.data, withdraw];
};

export const fetchSell = (state, sells) => {
    state.sells = sells;
};

export const sell = (state, sell) => {
    state.sells.data = [...state.sells.data, sell];
};

export const fetchSent = (state, sents) => {
    state.sents = sents;
};

export const sent = (state, sent) => {
    state.sents.data = [...state.sents.data, sent];
};


const WithdrawMutation = {
    fetchWithdraw,
    withdraw,
    fetchSell,
    sell,
    fetchSent,
    sent
};

export default WithdrawMutation;