import moment from 'moment'

const PlanGetter = {
    plans: state => state.plans,
    subscribe: state => state.subscribe,
    userPlan: state => state.userPlan,
    planSummary: state => state.planSummary,
    userPlanDefaultStatSummary: state => {
        if (!state.userPlan || !state.userPlan.data) {
            []
        }
        let amount = 0;
        let total = 0;
        let amountPenalty = 0;
        let totalPenalty = 0;
        let type = '';
        let isTypeSet = false
        for(const userPlan of state.userPlan.data) {
            const { savings, loans, investments } = userPlan
            for(const i in savings) {
                const {amount: plan_amount, amount_paid, due_at} = savings[i]
                if(moment(new Date).isAfter(moment(due_at))) {
                    amount += plan_amount - amount_paid
                    total++
                    if (!isTypeSet && type === '') {
                        type = 'savings'
                        isTypeSet = true
                    } else if (type !== '' && type !== 'savings') {
                        type = ''
                    }
                }
            }
            for(const i in loans) {
                const {amount: plan_amount, amount_paid, due_at, status} = loans[i]
                if(moment(new Date).isAfter(moment(due_at))) {
                    amount += plan_amount - amount_paid
                    total++
                    if (status === "PENALTY") {
                        amountPenalty += plan_amount - amount_paid
                        totalPenalty++
                    }
                    if (!isTypeSet && type === '') {
                        type = 'loan'
                        isTypeSet = true
                    } else if (type !== '' && type !== 'loan') {
                        type = ''
                    }
                }
            }
            for(const i in investments) {
                const {amount: plan_amount, amount_paid, due_at} = investments[i]
                if(moment(new Date).isAfter(moment(due_at))) {
                    amount += plan_amount - amount_paid
                    total++
                }
                if (!isTypeSet && type === '') {
                    type = 'investment'
                    isTypeSet = true
                } else if (type !== '' && type !== 'investment') {
                    type = ''
                }
            }
        }

        return [
            {
                status: "UNPAID & PENALTY",
                type,
                amount,
                total
            },
            {
                status: "PENALTY",
                type,
                amount: amountPenalty,
                total: totalPenalty
            },
            {
                status: "UNPAID",
                type,
                amount: amount - amountPenalty,
                total: total - totalPenalty
            }
        ]
    }
};

export default PlanGetter;