<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold"></h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="clearForm"
        >
          Create New
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Campaign Information
      </h6>
      <div v-if="!campaign || Object.keys(campaign).length === 0">
        select a record to view information
      </div>
      <div v-else>
        <div class="text-xs mb-2">
          This campaign is targeted towards {{ recepientCount }} recepient(s)
        </div>
        <div class="relative flex justify-between">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="resendCampaign"
            :disabled="loaders().sendCampaign"
          >
            Send Campaign 
          </button>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="initEditCampaign"
          >
            Edit Campaign
          </button>
        </div>
        <div>
          <h4 class="text-blueGray-400 text-xs mt-3 mb-2 font-bold uppercase">
            Previously sent history
          </h4>
          <table class="items-center w-full bg-transparent border-collapse">
            <thead class="thead-light">
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">Recepients</th>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">Failed</th>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">Progress</th>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(batch, i) in campaign.batches" :key="i">

                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">
                  {{ batch.total }}
                </td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">
                  {{ batch.failure_count }}
                </td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">
                  {{ batch.current === 0 ? 0 : (batch.current / batch.count) * 100 }}%
                </td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-4 text-center">
                  {{ moment(batch.created_at).format("DD MMM") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="modals.campaign" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Campaign
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('campaign', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            
            <form>
              <div class="flex flex-wrap">
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      v-model="v$.campaign.title.$model"
                    />
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.campaign.title.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Message
                    </label>
                    <input id="campaign_message" v-model="v$.campaign.message.$model" type="hidden" name="content">
                    <trix-editor input="campaign_message" @trix-initialize="onInit" @trix-change="onChange"></trix-editor>
                    <!-- <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      v-model="v$.campaign.title.$model"
                    /> -->
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.campaign.message.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="w-6/12 md:w-full px-4">
                  <div class="relative mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Recepients
                    </label>
                    <select v-model="v$.campaign.recepient.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                      <option v-for="(t, i) in recepients" :key="i" :value="t">
                      {{ t }}
                      </option>
                    </select>

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.campaign.activates_at.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="w-6/12 md:w-full px-4">
                  <div class="relative mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Schedule
                    </label>
                    <input 
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" 
                      id="schedule-date"
                      type="text"
                      placeholder="Schedule date"
                      v-model="v$.campaign.activates_at.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.campaign.activates_at.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                
              </div>

              <div class="relative w-full mb-3 px-4 mt-2">
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button" 
                  @click="saveCampaign" 
                  :disabled="loaders().editCampaign || loaders().addCampaign"
                >
                  <span v-if="loaders().editCampaign || loaders().addCampaign" class="flex loader loader-sm"></span><span v-else>Persist Campaign</span>
                </button>
              </div>
            </form>

          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('campaign', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.campaign" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import datepicker from 'js-datepicker'
import { recepients } from '@/utils'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedCampaign", "campaign_type" ],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedCampaign(oldCampaign) {
      this.campaign = oldCampaign;
      this.getRecipientCount()
    }
  },
  data() {
    return {
      campaign: this.selectedCampaign,
      modals: {},
      recepientCount: 0
    }
  },
  created() {
    this.moment = moment;
  },
  mounted() {
    this.recepients = recepients;
  },
  validations() {
    return {
      campaign: {
        title: { required },
        message: { required },
        category: { required },
        activates_at: {},
        recepient: { required }
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions(["editCampaign", "addCampaign", "countRecepients", "sendCampaign", "deleteCampaign"]),
    // ...mapMutations([]),
    saveCampaign() {
      const data = this.campaign;
      const q = data.id ? this.editCampaign(data) : this.addCampaign(data);
      q.then( res => {
        console.log(res)
      })
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    clearForm() {
      this.campaign = {
        category: this.campaign_type
      }
      this.toggleModal('campaign', true);
      this.initDate()
    },
    initEditCampaign() {
      this.campaign = this.selectedCampaign;
      this.toggleModal('campaign', true);
      this.initDate()
    },
    initDate() {
      const self = this;
      setTimeout(function() {
        datepicker("#schedule-date", {
          onSelect: (instance, date) => {
            console.log(instance)
            self.campaign.activates_at = date;
          },
          disabler: date => moment(new Date).isAfter(moment(date))
        });
      }, 500);
    },
    getRecipientCount: function() {
      const self = this;
      this.countRecepients(this.campaign).then( res => {
        self.recepientCount = res
      })
    },
    onChange(e) {
      this.campaign.message = e.target.innerHTML
    },
    onInit() {},
    resendCampaign() {
      this.sendCampaign(this.selectedCampaign).then(() => {
        toaster.success("campaign successfully resent.")
      })
    }
  }
}
</script>