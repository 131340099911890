export const fetchSettings = (state, settings) => {
  state.settings = settings;
};

export const addSettings = (state, setting) => {
  state.settings = state.settings.push(setting);
};

export const updateSettings = (state, setting) => {
  const settingIndex = state.settings.findIndex(
    (_setting) => _setting.id === setting.id
  );
  if (settingIndex < 0) {
    return;
  }
  state.settings[settingIndex] = setting;
};

export const deleteSettings = (state, setting) => {
  const settings = state.settings.filter(
    (_setting) => _setting.id !== setting.id
  );
  state.settings = settings;
};


const SettingsMutation = {
  fetchSettings,
  addSettings,
  updateSettings,
  deleteSettings
};

export default SettingsMutation;
