<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            {{ role.name }} users
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <h3 class="p-4">{{ role.name }}</h3>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 px-4 flex justify-between" v-for="(user, i) in role.users" :key="i">
          {{ user.name }}
          <button 
            class="bg-red-500 text-white active:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
            @click="removeUser(user)"
            v-if="has('role.detach.ability')"
          >
            <!-- detach -->
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>

      <form v-if="has('role.attach.ability')">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 p-4 font-bold uppercase">
          Add User
        </h6>
        <div class="flex flex-wrap">

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Users
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.search.$model" 
                @keyup="searchUser"
                placeholder="Search user"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.search.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              
              <select v-model="v$.user.$model" class="border-0 mt-4 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <template v-for='(_user, i) in users().data'>
                  <option v-if="!(role.users||[]).includes(_user.id)" :value="_user" :key="i">{{ _user.name }}</option>
                </template>
              </select>
            </div>
          </div> 
        </div>

        <div class="relative w-full mb-3 px-4 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="attachUser" 
            :disabled="loaders().addUser"
          >
            Add User
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [
    "selectedRole",
  ],
  methods: {
    ...mapActions([
      "addUser", 
      "detachUser",
      "getAllUsers"
    ]),
    ...mapGetters([
      "loaders",
      "users"
    ]),
    attachUser() {
      if ( !this.user.id ) {
        toaster.show('please select a user first');
        return false;
      }
      const data = {
        role: this.role,
        user: this.user
      };
      if ( !this.role.id ) {
        toaster.show('please select a role first');
        return false;
      }

      const self = this;
      this.addUser(data).then( () => {
        const exisitingUser = self.role.users.find( p => p.id === data.user.id)
        if ( !exisitingUser ) {
          self.role.users.push(data.user)
        }
      });
    },
    removeUser(user) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      const data = {
        role: this.role,
        user
      };
      if ( !this.role.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      const self = this;
      this.detachUser(data).then( () => {
        self.role.users = self.role.users.filter( p => p.id !== data.user.id)
      });
    },
    searchUser() {
      const self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        self.getAllUsers({search: self.search})
      }, 1000)
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      role: {},
      search: "",
      user: {},
      timer: null
    }
  },
  validations() {
    return {
      search: {},
      user: {}
    }
  },
  watch: {
    // whenever question changes, this function will run
    selectedRole(oldRole) {
      this.role = oldRole;
    },
  },
}
</script>
