<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Coin Preference</h6>
        <!-- <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="clearForm"
        >
          Add New Coin Preference
        </button> -->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Add New
        </h6>
        <div class="flex flex-wrap">

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Currency
              </label>
              <select v-model="v$.coin.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in market()' :key="i" :value="cur.symbol">{{ cur.symbol }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.coin.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Action
              </label>
              <select v-model="v$.action.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in ["whitelist", "blacklist"]' :key="i" :value="cur">{{ cur }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.action.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            
          </div> 

          

           

        </div>




        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="addCoin" 
            :disabled="loaders().addCoinPreference"
          >
            Save
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      coin: null,
      action: 'whitelist'
    }
  },
  mounted() {
    this.getMarket();
  },
  validations() {
    return {
      coin: { required },
      action: { }
    }
  },
  methods: {
    ...mapGetters(["loaders", "market"]),
    ...mapActions(["addCoinPreference", "getMarket"]),
    addCoin() {
      const data = {
        coin: this.coin,
        action: this.action
      }
      this.addCoinPreference(data);
    }
  }
}
</script>