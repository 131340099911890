import AccountAction from "./account.action";
import LoaderAction from "./loader.action";
import NotificationAction from "./notification.action";
import TransactionAction from "./transaction.action";
import UserAction from "./user.action";
import InsightAction from "./insight.action";
import PlanAction from "./plan.action";
import PredictionAction from "./prediction.action";
import SettingsAction from "./settings.action";
import BankAccountAction from "./bankAccount.action";
import WithdrawAction from "./withdraw.action";
import CoinsAction from "./coins.action";
import MetricsAction from "./metrics.action";
import CurrencyAction from "./currency.action";
import RoleAction from "./role.action";
import BusinessAction from "./business.action";
import CampaignAction from "./campaign.action";

const actions = {
    ...SettingsAction,
    ...TransactionAction,
    ...UserAction,
    ...PlanAction,
    ...LoaderAction,
    ...NotificationAction,
    ...AccountAction,
    ...InsightAction,
    ...PredictionAction,
    ...BankAccountAction,
    ...WithdrawAction,
    ...CoinsAction,
    ...MetricsAction,
    ...CurrencyAction,
    ...RoleAction,
    ...BusinessAction,
    ...CampaignAction
};

export default actions;