  <template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
  >
    <div class="px-6">
      <div class="mt-3">
        <h3
          class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
        >
          Business
        </h3>
        <template v-if="businesses() && businesses().data.length > 0">
          <div v-for="(business, i) in businesses().data" :key="i" @click="$emit('selectedBusiness', business)" class="mb-2 text-blueGray-600 mt-10 cursor-pointer">
            <i class="fas fa-home mr-2 text-lg text-blueGray-400"></i>
            {{ business.name }} - <i class="fas fa-pen mr-2 text-xs text-blueGray-400"></i>
            <br/><small>{{ business.support_email }}</small>
          </div>
        </template>
        <div class="mb-2 text-blueGray-600" v-else>
          <i class="fas fa-home mr-2 text-lg text-blueGray-400"></i>
          -- no business details yet! --
        </div>
      </div>
      <hr/>
      <div class="mt-6">
        <h3
          class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
        >
          Next Of Kin(s)
        </h3>
        <template v-if="nextOfKins() && nextOfKins().data.length > 0">
          <div v-for="(nextOfKin, i) in nextOfKins().data" :key="i" @click="$emit('selectedNextOfKin', nextOfKin)" class="mb-2 text-blueGray-600 mt-10 cursor-pointer">
            <i class="fas fa-home mr-2 text-lg text-blueGray-400"></i>
            {{ nextOfKin.name }} - <i class="fas fa-pen mr-2 text-xs text-blueGray-400"></i>
            <br/><small>{{ nextOfKin.phone }}</small>
          </div>
        </template>
        <div class="mb-2 text-blueGray-600" v-else>
          <i class="fas fa-home mr-2 text-lg text-blueGray-400"></i>
          -- no next of kin details yet! --
        </div>
      </div>
      
      <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12 px-4">
            <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
              {{ userProfile().about }}
            </p>
            <!-- <a href="javascript:void(0);" class="font-normal text-emerald-500">
              Show more
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      team2,
    };
  },
  methods: {
    ...mapGetters(["userProfile", "businesses", "nextOfKins"]),
  }
};
</script>
